import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from "dateformat";

const SingleItem = ({ index, data, paginateData }) => {
    const [userData, setuserData] = useState([]);
    useEffect(() => {
        if (data?.user_id) {
            fetch(`https://api.royeluniverse.com/api/admin/user/view/single/${data?.user_id}`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    setuserData(data.data)
                })
        }

    }, [])

    return (
        <>
            <tr>
                <td data-label="S.N">{(parseFloat(index + 1) + (parseFloat(paginateData?.page) * parseFloat(paginateData?.limit))) - 10}</td>
                <td data-label="User Name">
                    <Link to={`/admin/users/details/${userData?._id}`}>
                        <span className="fw-bold">{userData.fname} {userData.lname}</span>
                    </Link>

                </td>
                <td data-label="Promotion Name">
                    {data?.promotion_name}
                </td>

                <td data-label="Period">
                    {data?.period} {data?.period_type}
                </td>
                <td data-label="Amount">
                    {data?.amount}$ 
                </td>
                <td data-label="Profit Amount">
                    {data?.profit_amount}$ 
                </td>

                <td data-label="Expired Time">
                {dateFormat(data?.expired_time, "d-m-yyyy")}
                </td>

                <td data-label="Created">
                {dateFormat(data?.createdAt, "d-m-yyyy h:MM:ss TT")}
                </td>

                <td data-label="Status">

                {data.status === 0 ?
                        <span className="badge badge--warning text-warning">Running</span>
                        : ''}
                    {data.status === 1 ?
                        <span className="badge badge--success text-success">Complete</span>
                        : ''}
                    
                </td>
            </tr>
        </>
    );
};

export default SingleItem;