import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import SingleItem from "./SingleItem";
import { toast } from "react-toastify";
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "./../../CustomPagination/CustomPagination";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";

const Staff = () => {
    // view data
    const { authUser } = useContext(AuthContext);
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(`https://api.royeluniverse.com/api/admin/role/view`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data);
                setPaginateData(data);
                setLoading(false);
            });
    }, [results]);

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://api.royeluniverse.com/api/admin/role/view?page=${pageNumber}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = (id) => {
        fetch(`https://api.royeluniverse.com/api/admin/role/delete/${id}`, {
            method: "DELETE",
            headers: {
                "content-type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setResults(data);
                toast.error(`${data.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .catch((error) => console.log(error));
    };


    if (!(authUser?.manage_staff)) {
        if (authUser?.manage_staff === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        if (loading) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                        <h6 className="page-title">Staff</h6>
                        <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                            <Link
                                to="/admin/staff/add"
                                className="btn btn-sm btn-outline-primary p-2 addBtn"
                            >
                                <i className="las la-plus"></i>Add New Staff
                            </Link>
                        </div>
                    </div>
    
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card b-radius--10 ">
                                <div className="card-body p-0">
                                    <div className="table-responsive--md  table-responsive">
                                        <table className="table table--light style-two mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Editor Or Viewer</th>
                                                    <th>Role</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length !== 0 ? (
                                                    data.map((data, index) => {
                                                        if (data) {
                                                            return (
                                                                <SingleItem
                                                                    data={data}
                                                                    index={index}
                                                                    key={data._id}
                                                                    handleDelete={handleDelete}
                                                                    paginateData={paginateData}
                                                                ></SingleItem>
                                                            );
                                                        }
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td className="text-muted text-center" colspan="100%">
                                                            Data not found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
    
                                {/* paginateLinks */}
                                <CustomPagination
                                    data={paginateData}
                                    handlePage={handlePage}
                                ></CustomPagination>
                                {/* paginateLinks */}
                            </div>
                        </div>
                    </div>
                </>
            );
        }

    }


    
};

export default Staff;
