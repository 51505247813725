import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from "dateformat";
const SingleItem = ({ data, index, paginateData }) => {
    const [userData, setuserData] = useState([]);
    useEffect(() => {
        if (data?.user_email) {
            fetch(`https://api.royeluniverse.com/api/admin/user/view/single/email/${data?.user_email}`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    setuserData(data.data)
                })
        }

    }, [])

    return (
        <>
            <tr>
                <td data-label="S.N">
                    {(parseFloat(index + 1) + (parseFloat(paginateData?.page) * parseFloat(paginateData?.limit))) - 10}
                </td>


                <td data-label="Full Name">
                    {userData ?
                        <Link to={`/admin/users/details/${userData?._id}`}>
                            <span className="fw-bold">{userData?.fname} {userData?.lname}</span>
                        </Link>
                        :
                        <span className="fw-bold">{data?.fname} {data?.lname}</span>
                    }


                </td>
                <td data-label="Create At">
                    {dateFormat(data?.createdAt, "d-m-yyyy h:MM:ss TT")}
                </td>
                <td data-label="Action">
                    <div className="button--group">
                        <Link
                            to={`/admin/live/chats/History/${data._id}`}
                            className="btn btn-sm btn-outline-success"
                        >
                            <i className="las la-desktop"></i> View Chat
                        </Link>
                    </div>
                </td>

            </tr>
        </>
    );
};

export default SingleItem;