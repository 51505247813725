import React, { useContext, useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import { RotatingSquare } from "react-loader-spinner";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";


const UserPermissionView = () => {
    const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    if(authUser?._id){
        fetch(`https://api.royeluniverse.com/api/admin/permission/user/view/${authUser?._id}`, {
            method: "GET",
          })
            .then((res) => res.json())
            .then((data) => {
              setData(data.data);
              setLoading(false);
            });
    }
  }, [authUser?._id]);


  if (!(authUser?.user)) {
    if (authUser?.user === undefined) {
        return (
            <>
                <div className="loader-css">
                    <RotatingSquare
                        visible={true}
                        height="100"
                        width="100"
                        color="#4fa94d"
                        ariaLabel="rotating-square-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <h1>unauthorized</h1>
            </>
        );
    }



} else {
  if (loading) {
    return (
      <>
        <div className="loader-css">
          <RotatingSquare
            visible={true}
            height="100"
            width="100"
            color="#4fa94d"
            ariaLabel="rotating-square-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div class="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 class="page-title"> Users</h6>
          
        </div>
  
        <div class="row">
          <div class="col-lg-12">
            <div class="card b-radius--10 ">
              <div class="card-body p-0">
                <div class="table-responsive--md  table-responsive">
                  <table class="table table--light style--two">
                    <thead>
                      <tr>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Password</th>
                        <th>Country</th>
                        <th>Joined At</th>
                        <th>Balance</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length !== 0 ? (
                        data.map((data, index) => {
                          if (data) {
                            return (
                              <SingleItem
                                data={data}
                                index={index}
                                key={data._id}
                              ></SingleItem>
                            );
                          }
                        })
                      ) : (
                        <tr>
                          <td className="text-muted text-center" colspan="100%">
                            Data not found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
  
            </div>
          </div>
        </div>
      </>
    );
  }

}
 
 
};

export default UserPermissionView;