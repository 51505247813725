import React, { useContext, useEffect,  useState } from "react";
import SingleItem from "./SingleItem";
import axios from "axios";
import { Link } from "react-router-dom";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "./../../CustomPagination/CustomPagination";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";


const UserCompleteMining = () => {
  const { authUser } = useContext(AuthContext);
        // view data

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginateData, setPaginateData] = useState([]);
  useEffect(() => {
    fetch(`https://api.royeluniverse.com/api/admin/mining/user/complete/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);



  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.royeluniverse.com/api/admin/mining/user/complete/view?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };
  if (!(authUser?.mining)) {
    if (authUser?.mining === undefined) {
        return (
            <>
                <div className="loader-css">
                    <RotatingSquare
                        visible={true}
                        height="100"
                        width="100"
                        color="#4fa94d"
                        ariaLabel="rotating-square-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <h1>unauthorized</h1>
            </>
        );
    }



} else {
  if (loading) {
    return (
      <>
        <div className="loader-css">
          <RotatingSquare
            visible={true}
            height="100"
            width="100"
            color="#4fa94d"
            ariaLabel="rotating-square-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 className="page-title">Complete Mining List</h6>
          <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
          <Link
              to="/admin/user/all/mining/view"
              className="btn btn-sm btn-outline-primary addBtn"
            >
              <i className="fa-solid fa-list-ul me-1"></i>All Mining
            </Link>
          <Link
              to="/admin/user/running/mining/view"
              className="btn btn-sm btn-outline-primary addBtn"
            >
              <i className="fa-solid fa-list-ul me-1"></i>
              Running Mining
            </Link>
            
          
            <Link
              to="/admin/mining/add"
              className="btn btn-sm btn-outline-primary addBtn"
            >
              <i className="las la-plus"></i>Add New
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card b-radius--10 ">
              <div className="card-body p-0">
                <div className="table-responsive--md  table-responsive">
                  <table className="table table--light style--two mb-0">
                    <thead>
                      <tr>
                        <th>S.N</th>
                        <th>User Name</th>
                        <th>Promotion Name</th>
                        <th>Period </th>
                        <th>Amount </th>
                        <th>Profit Amount </th>                     
                        <th>Expired Time</th>
                        <th>Created</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length !== 0 ? (
                        data.map((data, index) => {
                          if (data) {
                            return (
                              <SingleItem
                                data={data}
                                index={index}
                                key={data._id}
                                paginateData={paginateData}
                              ></SingleItem>
                            );
                          }
                        })
                      ) : (
                        <tr>
                          <td className="text-muted text-center" colspan="100%">
                            Data not found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* paginateLinks */}
              <CustomPagination data={paginateData} handlePage={handlePage}>
                
              </CustomPagination>
              {/* paginateLinks */}
            </div>
          </div>
        </div>
      </>
    );
  }

}
 
};
export default UserCompleteMining;