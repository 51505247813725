import React, { useContext, useEffect, useState } from 'react';
import SingleChatsItem from './SingleChatsItem';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';
import { RotatingSquare } from 'react-loader-spinner';

const LiveChatHistoryDetails = () => {
    const { authUser } = useContext(AuthContext);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(`https://api.royeluniverse.com/api/admin/live/chat/conversation/message/view/${id}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data);
                setLoading(false);
            });
    }, []);
    const [UserData, setUserData] = useState([]);

    useEffect(() => {
        fetch(`https://api.royeluniverse.com/api/admin/live/chat/single/view/${id}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                setUserData(data.data);
            });
    }, []);

    if (!(authUser?.live_chat)) {
        if (authUser?.live_chat === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        return (
            <>
                <div class="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                    <h6 class="page-title"> {UserData?.fname} {UserData?.lname} Message History</h6>
                    <div class="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
    
                    </div>
                </div>
    
                <div className="container  chats-history ">
                    <div className="shadow-lg">
                        <div className="chat-card chat-app">
                            <div className="chat pt-3 m-0 px-5">
                                <div className="chat-history py-4">
                                    <ul className="m-b-0 ">
                                        {data.length !== 0 ? (
                                            data.map((data, index) => {
                                                if (data) {
                                                    return (
                                                        <SingleChatsItem data={data} index={index} key={index} ></SingleChatsItem>
                                                    );
                                                }
                                            })
                                        ) : (
                                            <tr>
                                                <td className="text-muted text-center" colSpan="100%">
                                                    Data not found
                                                </td>
                                            </tr>
                                        )}
    
    
                                    </ul>
                                </div>
    
                            </div>
    
                        </div>
                    </div>
    
                </div>
    
    
            </>
        );

    }

   
};

export default LiveChatHistoryDetails;