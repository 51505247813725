import React, {  useState, useRef, useContext } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import { RotatingSquare } from 'react-loader-spinner';


const MiningAdd = () => {
    const { authUser } = useContext(AuthContext);

    const [dataVulue, setDataVulue] = useState({});
    const refSubmitDis = useRef();

    const handleSubmitData = event => {
        event.preventDefault();
        refSubmitDis.current.setAttribute("disabled", true);
        const userData = { ...dataVulue,  };
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.post(`https://api.royeluniverse.com/api/admin/mining/store`, userData, config)
            .then(response => {
                event.target.reset();
                toast.success(`${response?.data.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                refSubmitDis.current.removeAttribute("disabled");
            }).catch((error) => {
                refSubmitDis.current.removeAttribute("disabled");
            });

    }

    const handleInputBlur = event => {
        const value = event.target.value;
        const field = event.target.name;
        const newData = { ...dataVulue };
        newData[field] = value;
        setDataVulue(newData);
    }

    if (!(authUser?.mining)) {
        if (authUser?.mining === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {


    }

    return (
        <>
            <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                <h6 className="page-title">Mining add</h6>
                <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 col-md-12 mb-30">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmitData}>
                                <div className="row">

                                <div className="col-md-12 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Mining Type</label>
                                            <select className="form-control" onBlur={handleInputBlur} name="mining_type" id="role" required>
                                                <option selected disabled>select</option>
                                                <option value="bitcoin"> Bitcoin Mining </option>
                                                <option value="gold"> Gold Mining </option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Promotion Name</label>
                                            <input type="text" className="form-control" name="promotion_name" onBlur={handleInputBlur} placeholder='promotion name' required id="heading" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Principal</label>
                                            <select className="form-control" onBlur={handleInputBlur} name="principal" id="role" required>
                                                <option selected disabled>select</option>
                                                <option value="1"> Returns </option>
                                                <option value="2"> Included </option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Period Time</label>
                                            <input type="number" className="form-control" name="period" onBlur={handleInputBlur} placeholder='period ' required id="heading" min='1' />
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Period Type</label>
                                            <select className="form-control" onBlur={handleInputBlur} name="period_type" id="role" required>
                                                <option selected disabled>select</option>
                                                <option value="Hours">Hours</option>
                                                <option value="Day">Day</option>
                                                <option value="Months">Months</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Profit%</label>
                                            <input type="text" className="form-control" name="percentage" onBlur={handleInputBlur} placeholder='profit' required id="heading" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Instant</label>
                                            <select className="form-control" onBlur={handleInputBlur} name="instant" id="role" required>
                                                <option selected disabled>select</option>
                                                <option value="1">Daily</option>
                                                <option value="2">After</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Minimum Amount</label>
                                            <input type="number" className="form-control" name="min_amount" onBlur={handleInputBlur} placeholder='min amount' required id="heading" min={0} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">maximum Amount</label>
                                            <input type="text" className="form-control" name="max_amount" onBlur={handleInputBlur} placeholder='max amount' required id="heading" />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Expired Time</label>
                                            <input type="date" className="form-control" name="expired_time" onBlur={handleInputBlur} placeholder='expired_time' required id="heading" />
                                        </div>


                                    </div>
                                </div>


                                <div className="form-group">
                                    <button ref={refSubmitDis} type="submit" className="btn btn-primary w-100 h-45">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default MiningAdd;