import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import SideBarSubMenu from './SideBarSubMenu';
import { useContext } from 'react';
import { AuthContext } from '../../../Contexts/AuthContext/AuthProvider';
import SideBarMenu from './SideBarMenu';
import Skeleton from 'react-loading-skeleton';

const SideBar = ({ menuSHow, setMenuShow }) => {
    const { authUser } = useContext(AuthContext);

    const routes = [
        {
            auth: authUser?.dashboard,
            path: "/admin/dashboard",
            name: "Dashboard",
            icon: "menu-icon las la-home",
        },
        {
            auth: authUser?.crypto_currency,
            path: "",
            name: "Trade List",
            icon: "menu-icon las la-coins",
            subRoutes: [
                {
                    path: "/admin/crypto/currency/list",
                    name: "Crypto Currency",
                },
                {
                    path: "/admin/forex/list",
                    name: "Forex",
                },
                {
                    path: "/admin/stock/list",
                    name: "Stocks (CFDs)",
                },
                {
                    path: "/admin/indices/list",
                    name: "Indices (CFDs)",
                },
                {
                    path: "/admin/energises/list",
                    name: "Energises (CFDs)",
                },
                
                {
                    path: "/admin/metals/list",
                    name: "Metals",
                },
                {
                    path: "/admin/popular/market/list",
                    name: "Popular Market",
                },
            ],

        },
        {
            auth: authUser?.trade_setting,
            path: "/admin/trade/setting",
            name: "Trade Setting",
            icon: "menu-icon las la-cog",
        },
        {
            auth: authUser?.manage_staff,
            path: "/admin/staff",
            name: "Manage Staff",
            icon: "menu-icon la-user-astronaut",
        },
        {
            auth: authUser?.trade_log,
            path: "",
            name: "Trade log",
            icon: "menu-icon las la-dice",
            subRoutes: [
                {
                    path: "/admin/trade/log",
                    name: "All",
                },
                {
                    path: "/admin/trade/log/wining",
                    name: "Wining",
                },
                {
                    path: "/admin/trade/log/losing",
                    name: "Losing",
                },
                {
                    path: "/admin/trade/log/draw",
                    name: "Draw",
                },
            ],
        },
       
        {
            auth: authUser?.manage_users,
            path: "/settings",
            name: "Manage Users",
            icon: "menu-icon las la-users",
            subRoutes: [
                {
                    path: "/admin/users/active",
                    name: "Active Users",
                },
                {
                    path: "/admin/users/banned",
                    name: "Banned Users",
                },
                {
                    path: "/admin/users/email-unverified",
                    name: "Email Unverified",
                },
                {
                    path: "/admin/users/mobile-unverified",
                    name: "Mobile Unverified",
                },
                {
                    path: "/admin/users/kyc-unverified",
                    name: "KYC Unverified",
                },
                {
                    path: "/admin/users/kyc-pending",
                    name: "KYC Pending",
                },
                {
                    path: "/admin/users/with-balance",
                    name: "With Balance",
                },
                {
                    path: "/admin/users/all",
                    name: "All Users",
                },
            ],
        },

        {
            auth: authUser?.user,
            path: "",
            name: "User",
            icon: "menu-icon las la-users",
            subRoutes: [
              
                {
                    path: "/admin/user/view",
                    name: "User",
                },
                {
                    path: "/admin/user/deposit/pending/view",
                    name: "Pending Deposit",
                },
                
                {
                    path: "/admin/user/withdrawal/pending/view",
                    name: "Pending Withdrawal",
                },
            ],
        },
        {
            auth: authUser?.payment_gateways,
            path: "",
            name: "Payment Gateways",
            icon: "menu-icon las la-credit-card",
            subRoutes: [
                {
                    path: "/admin/gateway/manual",
                    name: "Manual Gateways",
                },
            ],
        },
        {
            auth: authUser?.deposits,
            path: "",
            name: "Deposits",
            icon: "menu-icon las la-file-invoice-dollar",
            subRoutes: [
                {
                    path: "/admin/deposit/pending",
                    name: "Pending Deposits",
                },
                {
                    path: "/admin/deposit/approved",
                    name: "Approved Deposits",
                },
                {
                    path: "/admin/deposit/successful",
                    name: "Successful Deposits",
                },
                {
                    path: "/admin/deposit/rejected",
                    name: "Rejected Deposits",
                },
                {
                    path: "/admin/deposit",
                    name: "All Deposits",
                },
            ],
        },
        {
            auth: authUser?.withdrawals,
            name: "Withdrawals",
            icon: "menu-icon la la-bank",
            subRoutes: [
                {
                    path: "/admin/withdraw/method",
                    name: "Withdrawal Methods",
                },
                {
                    path: "/admin/withdraw/pending",
                    name: "Pending Withdrawal",
                },
                {
                    path: "/admin/withdraw/approved",
                    name: "Approved Withdrawal",
                },
                {
                    path: "/admin/withdraw/rejected",
                    name: "Rejected Withdrawal",
                },
                {
                    path: "/admin/withdraw/log",
                    name: "All Withdrawal",
                },
            ],
        },
        {
            auth: authUser?.investment_plan,
            name: "Investment Plan",
            icon: "menu-icon la la-bank",
            subRoutes: [
                {
                    path: "/admin/investment/plan/lists",
                    name: "Promotion",
                },
                {
                    path: "/admin/user/running/investment/plan/view",
                    name: "Running Investment Plan",
                },
                {
                    path: "/admin/user/complete/investment/plan/view",
                    name: "Complete Investment Plan",
                },
                {
                    path: "/admin/user/all/investment/plan/view",
                    name: "All Investment Plan",
                },

            ],
        },
        {
            auth: authUser?.mining,
            name: "Mining",
            icon: "menu-icon la la-bank",
            subRoutes: [
                {
                    path: "/admin/running/mining/view",
                    name: "Promotion",
                },
                {
                    path: "/admin/user/running/mining/view",
                    name: "Running Mining",
                },
                {
                    path: "/admin/user/complete/mining/view",
                    name: "Complete Mining",
                },
                {
                    path: "/admin/user/all/mining/view",
                    name: "All Mining",
                },

            ],
        },
        {
            auth: authUser?.loan,
            name: "Loan",
            icon: "menu-icon la la-bank",
            subRoutes: [
                {
                    path: "/admin/loan/package/view",
                    name: "Loan Package",
                },
                {
                    path: "/admin/user/pending/loan",
                    name: "Pending Loan",
                },
                {
                    path: "/admin/user/approved/loan",
                    name: "Approved Loan",
                },
                {
                    path: "/admin/user/reject/loan",
                    name: "Reject Loan",
                },
                {
                    path: "/admin/user/completed/loan",
                    name: "Completed Loan",
                },

            ],
        },

        {
            auth: authUser?.live_chat,
            name: "Live Chat",
            icon: "menu-icon la la-ticket",
            subRoutes: [
                {
                    path: "/admin/pending/live/chats/view",
                    name: "Pending Live Chats",
                },
                {
                    path: "/live/chats",
                    name: "Live Chats",
                },
                {
                    path: "/admin/live/chats/History",
                    name: "Live Chats History",
                },

            ],
        },
        {
            auth: authUser?.report,
            path: "/settings",
            name: "Report",
            icon: "menu-icon la la-list",
            subRoutes: [
                {
                    path: "/admin/users/transaction/logs",
                    name: "Transaction Log",
                },
                // {
                //     path: "/admin/users/active",
                //     name: "Notification History",
                // },         

            ],
        },
       
        {
            auth: authUser?.contact_us,
            path: "/admin/contact/us/view",
            name: "Contact Us",
            icon: "menu-icon la-thumbs-up",
        },
        {
            auth: authUser?.manage_section,
            path: "",
            name: "Manage Section",
            icon: "menu-icon la la-puzzle-piece",
            subRoutes: [
              
                {
                    path: "/admin/frontend/frontend-sections/slider",
                    name: "Slider Section",
                },
                {
                    path: "/admin/frontend/frontend-sections/notices",
                    name: "Notices Section",
                },
                
                {
                    path: "/admin/news/view",
                    name: "News",
                },
            ],
        },
      
        
    ];

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const inputAnimation = {
        hidden: {
            width: 0,
            padding: 0,
            transition: {
                duration: 0.2,
            },
        },
        show: {
            width: "140px",
            padding: "5px 15px",
            transition: {
                duration: 0.2,
            },
        },
    };

    const showAnimation = {
        hidden: {
            width: 0,
            opacity: 0,
            transition: {
                duration: 0.5,
            },
        },
        show: {
            opacity: 1,
            width: "auto",
            transition: {
                duration: 0.5,
            },
        },
    };

    const menuRef = useRef();

    const [mobileMenu, setMobileMenu] = useState();

    useEffect(() => {
        if (menuSHow === true) {
            menuRef.current.style.left = "0px";
        }
        if (mobileMenu === true) {
            menuRef.current.style.left = "-285px";
            setMenuShow(false);
            setMobileMenu(false)
        }

    }, [ mobileMenu, menuSHow]);
 

        return (
            <>  
                <div className="default-version">
                    <div className="sidebar bg--dark" >
                        <button className="res-sidebar-close-btn" onClick={() => setMobileMenu(true)}><i className="las la-times"></i></button>
                        <div className="sidebar__inner">
                            <div className="sidebar__logo">
                                <Link href="/admin/dashboard" className="sidebar__main-logo"><img src="https://gffexvip.biz/assets/images/logoIcon/logo.png" alt="" /></Link>
                            </div>

                            <div className="sidebar__menu-wrapper" id="sidebar__menuWrapper">
                                <ul className="sidebar__menu">
                                    {routes.map((route, index) => {
                                          if (authUser?._id === undefined) {
                                            return (
                                                <>
                                                      <Skeleton count={20} height={10} baseColor="#202020" highlightColor="#444" />
                                                </>
                                            );
                                        } else {
                                            if (route.subRoutes) {
                                                if (route?.auth === true) {
                                                    return (
                                                        <SideBarSubMenu
                                                            setIsOpen={setIsOpen}
                                                            route={route}
                                                            showAnimation={showAnimation}
                                                            isOpen={isOpen}
                                                            key={index}
                                                            setMobileMenu={setMobileMenu}
                                                        />
                                                    );
                                                }
    
                                            } else if (route.title) {
                                                return (
                                                    <li className="sidebar__menu-header" key={index}>{route.title}</li>
                                                );
    
                                            } else {
                                                if (route?.auth === true) {
                                                    return (
                                                        <SideBarMenu route={route} index={index} key={index} setMobileMenu={setMobileMenu}></SideBarMenu>
                                                    )
                                                }
    
                                            }
                                        }
                                     
                                    })}

                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="default-version desltop-version">
                    <div className="sidebar bg--dark" ref={menuRef}>
                        <button className="res-sidebar-close-btn" onClick={() => setMobileMenu(true)}><i className="las la-times"></i></button>
                        <div className="sidebar__inner">
                            <div className="sidebar__logo">
                                <Link href="/admin/dashboard" className="sidebar__main-logo"><img src="https://gffexvip.biz/assets/images/logoIcon/logo.png" alt="" /></Link>
                            </div>

                            <div className="sidebar__menu-wrapper" id="sidebar__menuWrapper">
                                <ul className="sidebar__menu">
                                    {routes.map((route, index) => {
                                          if (authUser?._id === undefined) {
                                            return (
                                                <>
                                                      <Skeleton count={20} height={10} baseColor="#202020" highlightColor="#444" />
                                                </>
                                            );
                                        } else {
                                            if (route.subRoutes) {
                                                if (route?.auth === true) {
                                                    return (
                                                        <SideBarSubMenu
                                                            setIsOpen={setIsOpen}
                                                            route={route}
                                                            showAnimation={showAnimation}
                                                            isOpen={isOpen}
                                                            key={index}
                                                            setMobileMenu={setMobileMenu}
                                                        />
                                                    );
                                                }
    
                                            } else if (route.title) {
                                                return (
                                                    <li className="sidebar__menu-header" key={index}>{route.title}</li>
                                                );
    
                                            } else {
                                                if (route?.auth === true) {
                                                    return (
                                                        <SideBarMenu route={route} index={index} key={index} setMobileMenu={setMobileMenu}></SideBarMenu>
                                                    )
                                                }
    
                                            }
                                        }
                                     
                                    })}

                                </ul>

                            </div>
                        </div>
                    </div>
                </div>



            </>
        );

};

export default SideBar;