import React, { useContext, useEffect, useState } from 'react';
import SingleItem from '../SingleItem';
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';

const ApprovedWithdrawals = () => {
    const { authUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);

    const [data, setData] = useState([]);
    const [dataSum, setdataSum] = useState([]);
    useEffect(() => {
        fetch(`https://api.royeluniverse.com/api/admin/withdrawal/accept`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data);
                setdataSum(data)
                setPaginateData(data);
                setLoading(false);
            })
    }, []);

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://api.royeluniverse.com/api/admin/withdrawal/accept?page=${pageNumber}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };
    if (!(authUser?.withdrawals)) {
        if (authUser?.withdrawals === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        if (loading) {
            return (
                <>
                    <div className="loader-css" >
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}
                            }
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div class="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                        <h6 class="page-title">Approved Withdrawals</h6>
                       
                    </div>
    
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-sm-6 mb-30">
                            <div class="widget-two box--shadow2 has-link b-radius--5 bg--success">
                                <div class="widget-two__content">
                                    <h2 class="text-white">${dataSum.WithdrawalAcceptSum}</h2>
                                    <p class="text-white">Approved Withdrawals</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-sm-6 mb-30">
                            <div class="widget-two box--shadow2 has-link b-radius--5 bg--6">
                                <div class="widget-two__content">
                                    <h2 class="text-white">{dataSum.length}</h2>
                                    <p class="text-white">Totals Approved Withdrawals</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card b-radius-10 ">
                                <div class="card-body p-0">
                                    <div class="col-md-12">
                                        <div class="card b-radius--10">
                                            <div class="card-body p-0">
                                                <div class="table-responsive--sm table-responsive">
                                                    <table class="table table--light style--two mb-0" >
                                                        <thead>
                                                            <tr>
                                                                <th>Network Type | Transaction</th>
                                                                <th>Initiated</th>
                                                                <th>Full Name</th>
                                                                <th>Amount</th>
                                                               
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.length !== 0 ?
                                                                data.map((data, index) => {
                                                                    if (data) {
                                                                        return (
                                                                            <SingleItem data={data} index={index} key={data._id} paginateData={paginateData}></SingleItem>
                                                                        );
                                                                    }
                                                                }) :
                                                                <tr>
                                                                    <td className="text-muted text-center" colspan="100%">Data not found</td>
                                                                </tr>}
    
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* paginateLinks */}
                                <CustomPagination
                                    data={paginateData}
                                    handlePage={handlePage}
                                > </CustomPagination>
                                {/* paginateLinks */}
                            </div>
                        </div>
                    </div>
                </>
            );
    
        }

    }
  

};

export default ApprovedWithdrawals;