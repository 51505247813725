import React from "react";
import dateFormat from "dateformat";
const SingleItem = ({ index, data, paginateData, adminData }) => {
  return (
    <>
      {/* https://www.ip2location.com/demo/ipadresss? */}
      <tr>
        <td data-label="S.N.">
          {" "}
          {parseFloat(index + 1) +
            parseFloat(paginateData?.page) * parseFloat(paginateData?.limit) -
            10}
        </td>
        <td data-label="Name">
          <div className="user">
            <span className="name">{adminData?.name}</span>
          </div>
        </td>
        <td data-label="IP">{data?.ip}</td>
        <td data-label="Login Time">
          {" "}
          {dateFormat(data?.createdAt, "d-m-yyyy h:MM:ss TT")}
        </td>

        <td data-label="Action">
          <a
            href={`https://www.ip2location.com/demo/${data?.ip}`}
            target="_blank"
            className="btn btn-sm btn-outline-primary ms-1"
            rel="noopener noreferrer"
          >
            <i className="la la-desktop"></i> Preview{" "}
          </a>
        </td>
      </tr>
    </>
  );
};

export default SingleItem;
