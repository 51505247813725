import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SingleItem from "./SingleItem";
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "../../../../CustomPagination/CustomPagination";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";

const DrawTradeLogHistory = () => {
  const { authUser } = useContext(AuthContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [paginateData, setPaginateData] = useState([]);

  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`https://api.royeluniverse.com/api/admin/user/trade/log/draw/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.royeluniverse.com/api/admin/user/trade/log/draw/${id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return (
      <>
        <div className="loader-css">
          <RotatingSquare
            visible={true}
            height="100"
            width="100"
            color="#4fa94d"
            ariaLabel="rotating-square-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 className="page-title">Draw Trade Log</h6>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card b-radius--10 ">
              <div className="card-body p-0">
                <div className="table-responsive--md  table-responsive">
                  <table className="table table--light style-two mb-0">
                    <thead>
                      <tr>
                        <th>S.N.</th>
                        <th>User</th>
                        <th>Crypto</th>
                        <th>Amount</th>
                        <th>In Time</th>
                        <th>HighLow</th>
                        <th>Result</th>
                        <th>Status</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length !== 0 ? (
                        data.map((data, index) => {
                          if (data) {
                            return (
                              <SingleItem
                                data={data}
                                index={index}
                                key={data._id}
                                paginateData={paginateData}
                              ></SingleItem>
                            );
                          }
                        })
                      ) : (
                        <tr>
                          <td className="text-muted text-center" colspan="100%">
                            Data not found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* paginateLinks */}
              <CustomPagination data={paginateData} handlePage={handlePage}>
                {" "}
              </CustomPagination>
              {/* paginateLinks */}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default DrawTradeLogHistory;
